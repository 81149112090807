:root {
  --color-grey-010: #FFFFFF;
  --color-grey-020: #F5F5F5;
  --color-grey-030: #EDEDED;
  --color-grey-040: #E0E0E0;
  --color-grey-050: #C2C2C2;
  --color-grey-060: #9E9E9E;
  --color-grey-070: #757575;
  --color-grey-080: #616161;
  --color-grey-090: #424242;
  --color-grey-100: #0A0A0A;
  
  --color-red-010: #FCF3F2;
  --color-red-020: #FADCD9;
  --color-red-030: #FABBB4;
  --color-red-040: #FC9086;
  --color-red-050: #FA5343;
  --color-red-060: #D91F11;
  --color-red-070: #A1160A;
  --color-red-080: #75160C;
  --color-red-090: #4F150F;
  --color-red-100: #24120C;
  
  --color-orange-010: #FCF2EB;
  --color-orange-020: #FCDDC7;
  --color-orange-030: #FCBC97;
  --color-orange-040: #FC9162;
  --color-orange-050: #E86427;
  --color-orange-060: #BF4815;
  --color-orange-070: #8F3415;
  --color-orange-080: #632B17;
  --color-orange-090: #402117;
  --color-orange-100: #1F1410;
  
  --color-yellow-010: #FAF6CF;
  --color-yellow-020: #F7E379;
  --color-yellow-030: #F5C518;
  --color-yellow-040: #D9A514;
  --color-yellow-050: #B3870E;
  --color-yellow-060: #946613;
  --color-yellow-070: #70491C;
  --color-yellow-080: #54341F;
  --color-yellow-090: #38251B;
  --color-yellow-100: #1C1613;
  
  --color-lime-010: #EBF7DA;
  --color-lime-020: #D5F0B1;
  --color-lime-030: #AAD971;
  --color-lime-040: #78BF39;
  --color-lime-050: #52A31D;
  --color-lime-060: #3C7D0E;
  --color-lime-070: #2E5C0E;
  --color-lime-080: #254211;
  --color-lime-090: #1C2E10;
  --color-lime-100: #121A0D;
  
  --color-green-010: #EBF7ED;
  --color-green-020: #C7EBD1;
  --color-green-030: #88DBA8;
  --color-green-040: #43C478;
  --color-green-050: #16A163;
  --color-green-060: #077D55;
  --color-green-070: #075E45;
  --color-green-080: #094536;
  --color-green-090: #092E25;
  --color-green-100: #081A15;
  
  --color-teal-010: #EBF5F4;
  --color-teal-020: #BEEBE7;
  --color-teal-030: #86D9D4;
  --color-teal-040: #4EBFB9;
  --color-teal-050: #279C9C;
  --color-teal-060: #167B7D;
  --color-teal-070: #155C5E;
  --color-teal-080: #124241;
  --color-teal-090: #102E2D;
  --color-teal-100: #0C1A19;
  
  --color-torquoise-010: #EBF5F4;
  --color-torquoise-020: #C7E8ED;
  --color-torquoise-030: #81D8E6;
  --color-torquoise-040: #45BCD1;
  --color-torquoise-050: #159AB3;
  --color-torquoise-060: #067A91;
  --color-torquoise-070: #09596B;
  --color-torquoise-080: #0C424D;
  --color-torquoise-090: #102D33;
  --color-torquoise-100: #0F181A;
  
  --color-aqua-010: #EBF3F7;
  --color-aqua-020: #C9E7F5;
  --color-aqua-030: #8BD3F7;
  --color-aqua-040: #48B8F0;
  --color-aqua-050: #1195D6;
  --color-aqua-060: #0073BA;
  --color-aqua-070: #08548A;
  --color-aqua-080: #0E3D66;
  --color-aqua-090: #0C2B45;
  --color-aqua-100: #0B1724;
  
  --color-blue-010: #F0F4FA;
  --color-blue-020: #D4E4FA;
  --color-blue-030: #ADCCF7;
  --color-blue-040: #75B1FF;
  --color-blue-050: #3D8DF5;
  --color-blue-060: #186ADE;
  --color-blue-070: #0D4EA6;
  --color-blue-080: #103A75;
  --color-blue-090: #11294D;
  --color-blue-100: #0D1826;
  
  --color-purple-010: #F7F2FC;
  --color-purple-020: #EADCFC;
  --color-purple-030: #DABEFA;
  --color-purple-040: #C89AFC;
  --color-purple-050: #AC71F0;
  --color-purple-060: #8F49DE;
  --color-purple-070: #6B30AB;
  --color-purple-080: #4C277D;
  --color-purple-090: #331F4D;
  --color-purple-100: #1C1229;
  
  --color-pink-010: #FCF0F8;
  --color-pink-020: #F7DAED;
  --color-pink-030: #F7B7E2;
  --color-pink-040: #FA87D4;
  --color-pink-050: #ED4CB7;
  --color-pink-060: #CC1D92;
  --color-pink-070: #961574;
  --color-pink-080: #6B155A;
  --color-pink-090: #47153F;
  --color-pink-100: #241020;
  
  --color-black-100: #000000;
    /*--color-primary: rgb(54, 182, 180);
    --color-primary-light: rgba(54, 182, 180, 0.23137254901960785);
    --color-primary-extra-light: rgba(54, 182, 180, 0.07058823529411765);
    --color-complementary: rgb(182, 54, 56);
    --color-background-dark: rgba(33, 33, 33, 0.10196078431372549);
    --color-background-light: rgb(250, 250, 250);
    --color-body-overflow: rgb(25, 39, 51);
    --color-white: rgb(255, 255, 255);
    --color-black: rgb(33, 33, 33);
    --color-mute: rgba(33, 33, 33, 0.6);
    --color-dull: rgba(33, 33, 33, 0.12156862745098039);
    --color-red: rgb(213, 0, 0);*/
    /*To be removed*/
    /*--color-analogous: rgb(54, 182, 116);
    --color-triadic1: rgb(54, 56, 182);
    --color-triadic2: rgb(180, 54, 182);*/
    
    
    /* 
      Demo Fedex Styles below
    */
    --color-fedex-010: #f2e9fc;
    --color-fedex-020: #d7bcf5;
    --color-fedex-030: #bd8fef;
    --color-fedex-040: #a263e9;
    --color-fedex-050: #a263e9;
    --color-fedex-060: #4d148c;
    --color-fedex-070: #56169c;
    --color-fedex-080: #3d1070;
    --color-fedex-090: #250a43;
    --color-fedex-100: #0c0316;

  
    --color-fedex-orange-dark:#ff6200;
    --color-fedex-orange-light: #e45528;

    --color-fedex-blue-light: #007AB7;
    --color-fedex-blue-dark: #00689C;
    
  }
  