:root {
  --color-background-dark: #3e4c59;
  --color-background-light: #f5f7fa;
  --color-body-overflow: #192733;
  --color-white: var(--color-grey-010);
  --color-black: #1f2933;
  --color-mute: #616e7c;
  --color-dull: #cbd2d9;
  --color-red: var(--color-red-060);
  --color-border: #e4e7eb;
  --color-highlight: #e4e7eb;
  --color-highlight-dark: #cbd2d9;
  --color-placeholder-default: #757575;
  --color-pending:#D97706;
  --color-grey-10: #f5f7fa;
  --color-grey-20: #e4e7eb;
  --color-grey-30: #cbd2d9;
  --color-grey-40: #616e7c;
 

  --color-link: #0079D0;
  --color-gray: #eee;
  --color-dark-gray: #bbb;
  --color-green: var(--color-green-060); 
  --color-badge-red: var(--color-red-060); 
  --color-success: var(--color-green-060);
  --color-success-background: var(--color-green-010);
  --color-warning: var(--color-yellow-040);
  --color-warning-background: var(--color-yellow-010);
  --color-error: var(--color-red-060);
  --color-error-background: var(--color-red-010);



  --color-primary: var(--color-fedex-060);
  --color-primary-light: var(--color-fedex-050);
  --color-primary-extra-light: var(--color-fedex-040);
  --color-primary-background: var(--color-fedex-010);
  --color-primary-active: var(--color-fedex-070); 
  --color-secondary-dark: var(--color-fedex-orange-dark);
  --color-secondary: var(--color-fedex-orange-dark);
  --color-secondary-light: var(--color-fedex-orange-light);
  --color-secondary-extra-light: var(--color-fedex-orange-light);
  --color-secondary-background: var(--color-fedex-orange-light);
}
