@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&style=outline&display=block');


/* @font-face {
  font-family: 'FedExSans';
  src: url('../fonts/fx-sans-rg.woff2') format('woff2'),
      url('../fonts/fx-sans-bd.woff2') format('woff2'),
      url('../fonts/fx-sans-it.woff2') format('woff2'),
} */

@font-face {
  font-family: FedExSans;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/FedExSans_W_Lt.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/FedExSans_W_LtIt.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/FedExSans_W_Rg.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/FedExSans_W_It.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/FedExSans_W_Md.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/FedExSans_W_MdIt.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/FedExSans_W_Bd.woff2") format('woff2')
}
@font-face {
  font-family: FedExSans;
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/FedExSans_W_BdIt.woff2") format('woff2')
}

body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, button, input, textarea, .small, .subtitle, .subtitleSmall, .caption, .overline, .muted {
  font-family: FedExSans, Arial, sans-serif;
}

body, .body {
  font-family: FedExSans, Arial, sans-serif;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px !important; 
  color: var(--color-grey-100);
  background-color: var(--color-grey-010) !important;
}

h1, .h1 {
  /* font-family: "FedExSans", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px; */
  letter-spacing: -0.4px;
  margin-top: 0px;
  margin-bottom: 0px;

  font-family: FedExSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 44px;
  line-height: 64px;;
}

h2, .h2 {
  /* font-family: "FedExSans", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px; */
  letter-spacing: -0.4px;
  margin-top: 0px;
  margin-bottom: 0px;

  font-family: FedExSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 56px;
}

h3, .h3 {
  /* font-family: "FedExSans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; */
  letter-spacing: -0.4px;
  margin-top: 0px;
  margin-bottom: 0px;

  font-family: FedExSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
}

h4, .h4 {
  /* font-family: "FedExSans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px; 
  letter-spacing: 0.16px;*/
  letter-spacing: -0.4px;
  margin-top: -0.4px;
  margin-bottom: 0px;

  font-family: FedExSans, Arial, sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

h5, .h5 {
  font-family: FedExSans, Arial, sans-serif;
  letter-spacing: -0.4px;
  font-weight: 300;
  font-size: 20px;
  /* letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0px; */

  font-family: FedExSans, Arial, sans-serif;
  line-height: 24px;
}

h6, .h6 {
  font-family: FedExSans, Arial, sans-serif;
  letter-spacing: -0.4px;
  font-weight: 400;
  font-size: 14px;
  /* letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0px; */

  font-family: FedExSans, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}


button {
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1;
}

.heading{
  font-family: FedExSans, Arial, sans-serif;
  line-height: 20px;
  font-weight: 400 !important;
  font-size: 14px !important; 
}

.smallThin {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: FedExSans, Arial, sans-serif;
}

.small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: FedExSans, Arial, sans-serif;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
}

.subtitleSmall {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
  
}

.overline {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.muted {
  color: var(--color-mute, #616e7c);
}

.sidenav{
  font-family: FedExSans, Arial, sans-serif;  
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.sidenavSubItem{
  font-family: FedExSans, Arial, sans-serif; 
  font-weight: 500;
  font-size: 12px;
}

.subHeading{
  font-family: FedExSans, Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}